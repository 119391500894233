import React, { useEffect, useState, useRef } from "react";
import "./style.css";

const TitleLabel = ({ label, className }) => {
  return (
    <span className={`ui-title-label ${className}`}>
      {label.toLocaleUpperCase("tr-TR")}
    </span>
  );
};

export default TitleLabel;
