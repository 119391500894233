import React, { useEffect, useState } from "react";
import BasicButton from "../../../ui/buttons/basicButton";
import PageRow from "../../../ui/rows/pageRow";
import PageColumn from "../../../ui/columns/pageColumn";
import TitleLabel from "../../../ui/labels/titleLabel";
import PageLayout from "../../../ui/layouts/pageLayout";
import Table from "../../../ui/table";
import { useTranslation } from "react-i18next";
import { getAppointments } from "../../../services/appointments";
import { useSelector } from "react-redux";
import moment from "moment";
import SubTitleLabel from "../../../ui/labels/subTitleLabel";

const AppointmentAlert = ({}) => {
  const { t } = useTranslation();

  const initialTableData = {
    tableId: "appointments",
    columns: [
      {
        field: "date",
        header: t("last_appointment_date"),
        dataType: "date",
      },
      {
        field: "person_name",
        header: t("person_name"),
        dataType: "text",
      },
    ],
    data: [],
  };

  const [tableData, setTableData] = useState(initialTableData);

  useEffect(() => {
    getRequiredData();
  }, []);

  const getRequiredData = async () => {
    const [appointmentsData] = await Promise.all([getAppointments()]);

    console.log("appointmentsData", appointmentsData);

    let resultArray = [];

    const sortedAppointmentsData = appointmentsData.sort(function (a, b) {
      return (
        moment(a.date, "YYYY-MM-DD").toDate() -
        moment(b.date, "YYYY-MM-DD").toDate()
      );
    });

    const twentyDaysAgo = moment().subtract(20, "days");
    sortedAppointmentsData.forEach((appointment) => {
      if (moment(appointment.date, "YYYY-MM-DD").isBefore(twentyDaysAgo)) {
        const found = resultArray.find(
          (item) => item.person_id === appointment.person_id
        );
        if (!found) {
          resultArray.push(appointment);
        }
      } else {
        resultArray = resultArray.filter(
          (item) => item.person_id !== appointment.person_id
        );
      }
    });

    console.log("resultArray", resultArray);

    setTableData({ ...tableData, data: resultArray });
  };

  return (
    <PageLayout>
      <PageRow className="col-12">
        <PageColumn className="col-6">
          <TitleLabel label={t("those_who_dont_come")} />
        </PageColumn>
      </PageRow>
      <PageRow className="col-12">
        <PageColumn className="col-12">
          <Table tableOptions={tableData} tableTitle="" />
        </PageColumn>
      </PageRow>
    </PageLayout>
  );
};

export default AppointmentAlert;
