import React from "react";
import "./style.css";

const InfoSticker = ({ className, label, value, icon }) => {
  return (
    <div className={`ui-info-sticker ${className}`}>
      <div className="ui-info-sticker-icon">{icon}</div>
      <span className="ui-info-sticker-label">{label}</span>
      <span className="ui-info-sticker-value">{value}</span>
    </div>
  );
};

export default InfoSticker;
