import React, { useEffect, useState } from "react";
import BasicButton from "../../../ui/buttons/basicButton";
import PageRow from "../../../ui/rows/pageRow";
import PageColumn from "../../../ui/columns/pageColumn";
import TitleLabel from "../../../ui/labels/titleLabel";
import PageLayout from "../../../ui/layouts/pageLayout";
import Table from "../../../ui/table";
import { useTranslation } from "react-i18next";
import { getPersons } from "../../../services/persons";
import moment from "moment";

const BirthdayAlert = ({}) => {
  const { t } = useTranslation();

  const initialTableData = {
    tableId: "persons",
    columns: [
      {
        field: "first_name",
        header: t("first_name"),
        dataType: "text",
      },
      {
        field: "last_name",
        header: t("last_name"),
        dataType: "text",
      },
      {
        field: "date_of_birth",
        header: t("date_of_birth"),
        dataType: "date",
      },
    ],
    data: [],
  };

  const [tableData, setTableData] = useState(initialTableData);

  useEffect(() => {
    getRequiredData();
  }, []);

  const getRequiredData = async () => {
    const [personData] = await Promise.all([getPersons()]);

    let resultArray = personData.filter(
      (item) =>
        moment(item.date_of_birth, "YYYY-MM-DD").format("DD-MM") ===
        moment().format("DD-MM")
    );

    setTableData({ ...tableData, data: resultArray });
  };

  return (
    <PageLayout>
      <PageRow className="col-12">
        <PageColumn className="col-6">
          <TitleLabel label={t("those_born_today")} />
        </PageColumn>
      </PageRow>
      <PageRow className="col-12">
        <PageColumn className="col-12">
          <Table tableOptions={tableData} tableTitle="" />
        </PageColumn>
      </PageRow>
    </PageLayout>
  );
};

export default BirthdayAlert;
